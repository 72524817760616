<template>
  <div class="layout">
    <div style="width: 100%; display: flex; align-items: center">
      <el-form
        :inline="true"
        :model="searchModel"
        style="margin-top: 20px; padding-left: 20px"
        size="mini"
      >
        <el-form-item label="结算日期:">
          <el-date-picker
            v-model="searchModel.settlementStartDate"
            type="date"
            placeholder="选择日期时间"
            style="width: 180px"
            value-format="yyyy-MM-dd"
          />
          --
          <el-date-picker
            v-model="searchModel.settlementEndDate"
            type="date"
            placeholder="选择日期时间"
            style="width: 180px"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="工程名称:" prop="projId">
          <el-select
            v-model="searchModel.projId"
            placeholder="请选择工程"
            filterable
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in projList"
              :key="item.proj"
              :label="item.projName"
              :value="item.proj"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称:" prop="compName">
          <el-input v-model="searchModel.compName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="submitSearch"
            :loading="loading"
          >{{ searchText }}
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-close"
            v-if="loading"
            @click="loading = false"
          >停止</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            v-auth="'砂浆销售结算_新增'"
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="
              addRow();
            "
          >新增
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <resize-wrap class="flex-1">
      <BaseTable
        id="settlementTab"
        ref="xTable1"
        size="mini"
        border
        auto-resize
        resizable
        highlight-current-row
        highlight-hover-row
        export-config
        :align="allAlign"
        :seq-config="{
          startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
        }"
        :data="tableData"
      >
        <vxe-table-column type="seq" title="序号" width="60" />

        <vxe-table-column
          field="compName"
          title="企业名称"
          show-overflow="title"
          min-width="120"
          show-header-overflow="title"
        /><vxe-table-column
          field="checkTime"
          title="结算日期"
          show-overflow="title"
          min-width="60"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="projName"
          title="工程名称"
          min-width="100"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="totalTrayNum"
          title="数量(吨)"
          show-overflow="title"
          min-width="120"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="totalAmount"
          title="金额(元)"
          show-overflow="title"
          min-width="120"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="remark"
          title="备注"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column title="操作" min-width="100">
          <template v-slot="{ row }">
            <el-button
              type="info"
              icon="el-icon-printer"
              circle
              size="mini"
              @click="printRow(row)"
              v-auth="'砂浆销售结算_打印'"
              title="打印"
            />
            <el-button
              icon="el-icon-download"
              circle
              size="mini"
              @click="exportTabData(row)"
              v-auth="'砂浆销售结算_导出'"
              title="导出"
            />
            <el-button
              type="success"
              icon="el-icon-tickets"
              circle
              size="mini"
              @click="detailRow(row)"
              v-auth="'砂浆销售结算_详情'"
              title="详情"
            />
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              size="mini"
              @click="editRow(row)"
              v-auth="'砂浆销售结算_修改'"
              title="修改"
            />
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="mini"
              @click="deleteRow(row)"
              v-auth="'砂浆销售结算_删除'"
              title="删除"
            />
          </template>
        </vxe-table-column>
      </BaseTable>
    </resize-wrap>
    <vxe-pager
      :loading="loading"
      :current-page.sync="tablePage.currentPage"
      :page-size.sync="tablePage.pageSize"
      :total="tablePage.total"
      :layouts="[
        'Sizes',
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'FullJump',
        'Total',
      ]"
      @page-change="handlePageChange"
    />
    <settlement-print :param="param" :visible="printDialog" @closePrint="printDialog = false"></settlement-print>
  </div>
</template>

<script>
import { getProjList0, getSettlementPage, delSettlement, settlementExport } from '@/apis/saleManage/settlementMortar'
import settlementPrint from './settlementPrint'
import { exportToxlsx } from '@/utils/tools'
export default {
  name: 'settlement-mortar',
  props: {
  },
  components: {
    settlementPrint
  },
  data () {
    return {
      searchModel: {
        settlementEndDate: '',
        settlementStartDate: '',
        projName: '',
        projId: ''
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: [
          'Sizes',
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'FullJump',
          'Total'
        ],
        perfect: true
      },
      param: undefined,
      projList: [],
      printDialog: false
    }
  },
  methods: {
    exportTabData (row) {
      settlementExport({
        id: row.settlementCheckId
      }).then(res => {
        exportToxlsx(res, '结算单')
      })
    },
    gainProjList () {
      // 关联工程
      getProjList0().then(res => {
        this.projList = res.data
      })
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () {
      getSettlementPage({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.code == 200) {
            const data = res.data
            this.tableData = data.rows
            this.tablePage.total = data.totalSize
          } else {
            this.tableData = []
            this.tablePage.total = 0
          }
        })
        .catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
    },
    addRow () {
      this.$emit('toDetailPage', true, undefined)
    },
    editRow (row) {
      this.$emit('toDetailPage', true, {
        id: row.settlementCheckId
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该条结算单记录?', '删除结算单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delSettlement({
          ids: [row.settlementCheckId]
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    printRow (row) {
      this.param = {
        id: row.settlementCheckId
      }
      this.printDialog = true
    },
    detailRow (row) {
      this.$emit('toDetailPage', false, {
        id: row.settlementCheckId
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {
    }
  },
  mounted () {
    this.gainProjList()
    this.refreshData()
  }
}
</script>s

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
</style>
