<template>
  <page-container>
    <settlement v-if="listOrDetail" @toDetailPage="toDetailPage" />
    <settlement-detail v-else @toPage="toPage" :edit-mode="editMode" :param="param"/>
  </page-container>
</template>

<script>
import settlement from './settlement'
import settlementDetail from './settlementDetail'
export default {
  name: 'layout',
  data () {
    return {
      listOrDetail: true,
      editMode: false,
      settleCheckId: '',
      param: ''
    }
  },
  components: {
    settlement,
    settlementDetail
  },
  methods: {
    toDetailPage (editMode, param) {
      this.listOrDetail = false
      this.editMode = editMode
      this.param = param
    },
    toPage () {
      this.listOrDetail = true
    }
  }
}
</script>

<style scoped>

</style>
