import http from '@/utils/http'
// 销售结算分页
export const getSettlementPage = p => http.post('/unibu/contract/settlementpage', p)

// 销售结算新增或者编辑
export const addModSettlement = p => http.post('/unibu/contract/settlementAddMod', p)

// 销售结算详情
export const gainSettlementOne = p => http.post('/unibu/contract/settlementOne', p)

// 销售结算删除
export const delSettlement = p => http.post('/unibu/contract/settlementDel', p)

// 关联工程接口
export const getProjList0 = p => http.post('/unibu/proj/list0', p)

// 销售结算-新增-发货数据获取
export const gianSettlementList = p => http.post('/unibu/contract/settlementlist', p)

// 销售结算-导出
export const settlementExport = p => http.post('/unibu/contract/settlementExport', p)
