<template>
  <div class="layout">
    <div style="width: 100%; display: flex; align-items: center">
      <el-form
        :inline="true"
        :model="searchModel"
        style="margin-top: 20px; padding-left: 20px"
        size="mini"
      >
        <el-form-item label="工程名称:" prop="projId">
          <el-select
            :disable="(editMode&&param)"
            v-model="searchModel.projId"
            placeholder="请选择工程"
            filterable
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in projList"
              :key="item.proj"
              :label="item.projName"
              :value="item.proj"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发货日期:">
          <el-date-picker
            v-model="searchModel.OutFacStartDate"
            type="date"
            placeholder="选择日期时间"
            style="width: 180px"
            value-format="yyyy-MM-dd"
          />
          --
          <el-date-picker
            v-model="searchModel.OutFacEndDate"
            type="date"
            placeholder="选择日期时间"
            style="width: 180px"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="submitSearch"
            :loading="loading"
          >{{ searchText }}
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-close"
            v-if="loading"
            @click="loading = false"
          >停止</el-button
          >
        </el-form-item>
        <el-form-item label="结算时间:">
          <el-date-picker
            v-model="searchModel.checkTime"
            type="date"
            placeholder="选择日期时间"
            style="width: 180px"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="remark"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <resize-wrap class="flex-1">
      <div class="tableTitle">结算明细</div>
      <vxe-table
        ref="xTable1"
        size="mini"
        border
        auto-resize
        resizable
        highlight-current-row
        highlight-hover-row
        export-config
        :align="allAlign"
        :data="list"
      >
        <vxe-table-column type="seq" title="序号" width="60" />
        <vxe-table-column
          field="createTime"
          title="发货日期"
          show-overflow="title"
          min-width="120"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="taskID"
          title="任务单号"
          show-overflow="title"
          min-width="60"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="serialID"
          title="发货单号"
          min-width="100"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="mortarType"
          title="砂浆品种"
          show-overflow="title"
          min-width="120"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="technicalRequirement"
          title="技术要求"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="FormulaNo"
          title="产品名称"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <!--        <vxe-table-column
          field="null"
          title="包装类型"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />-->
        <vxe-table-column
          field="confirmWeight"
          title="签收数量(吨)"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="unitPrice"
          title="物品单价"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="amount"
          title="物品金额"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="sendTray"
          title="发出托盘"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="receiveTray"
          title="回收托盘"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="licPlate"
          title="车牌号"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="distance"
          title="运距"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="driverName"
          title="驾驶员"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="remark"
          title="备注"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
      </vxe-table>
      <div class="tableTitle tableTitle_border">结算汇总</div>
      <el-form
        :inline="true"
        style="margin-top: 20px; padding-left: 20px"
        size="mini"
      >
        <el-form-item label="运费补贴:">
          <el-input v-model="primage"></el-input>
        </el-form-item>
        <el-form-item label="签收托盘:">
          <el-input v-model="totalTrayNum" disabled></el-input>
        </el-form-item>
        <el-form-item label="托盘单价:">
          <el-input v-model="trayUnitPrice"></el-input>
        </el-form-item>
        <el-form-item label="签收托盘金额:">
          <el-input v-model="totalAmount"></el-input>
        </el-form-item>
      </el-form>
      <vxe-table
        ref="xTable2"
        size="mini"
        border
        auto-resize
        resizable
        highlight-current-row
        highlight-hover-row
        export-config
        show-footer
        :footer-method="footerMethod"
        :align="allAlign"
        :data="settlementCountList"
        :edit-config="{trigger: 'click', mode: 'cell', icon: 'fa fa-pencil', showStatus: true}"
      >
        <vxe-table-column type="seq" title="序号" width="60" />
        <vxe-table-column
          field="strengthGrade"
          title="砂浆品种"
          show-overflow="title"
          min-width="120"
          show-header-overflow="title"
        /><vxe-table-column
          field="technical"
          title="技术要求"
          show-overflow="title"
          min-width="60"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="concreteVarietyName"
          title="产品名称"
          min-width="100"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="totalAmount"
          title="包装类型"
          show-overflow="title"
          min-width="120"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="confirmWeight"
          title="签收数量(吨)"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="unitPrice"
          title="单价"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
          :edit-render="{defaultValue: 0.0}"
        >
          <template v-slot:edit="scope">
            <vxe-input v-model="scope.row.unitPrice" type="text"  @change="calByPrice(scope)"></vxe-input>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="amount"
          title="金额"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="remark"
          title="备注"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
      </vxe-table>
      <div v-if="editMode" class="buttonWrapper">
        <el-button
          type="primary"
          icon="el-icon-refresh-left"
          @click="reset"
        >重置
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-finished"
          @click="submit"
        >确定</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-back"
          @click="reback"
        >返回
        </el-button>
      </div>
    </resize-wrap>
  </div>
</template>

<script>
import moment from 'moment'
import { getProjList0, gianSettlementList, gainSettlementOne, addModSettlement } from '@/apis/saleManage/settlementMortar'
export default {
  name: 'settlement-mortar',
  props: {
    editMode: {
      type: Boolean,
      default: false,
      require: true
    },
    param: {
      type: Object // 结算单流水号
    }
  },
  data () {
    return {
      refreshInit: true,
      remark: '',
      checkTime: moment().format('yyyy-mm-dd'),
      list: [], // 主表
      settlementCountList: [], // 汇总详情
      trayUnitPrice: 0.0, // 托盘单价
      primage: 0.0, // 运费补贴
      totalTrayNum: 0, // 签收托盘数
      searchModel: {
        OutFacEndDate: moment().endOf('month').format('YYYY-MM-DD'),
        OutFacStartDate: moment().startOf('month').format('YYYY-MM-DD'),
        projId: '',
        checkTime: moment().format('YYYY-MM-DD'),
        remark: ''
      },
      searchText: '获取发货单',
      loading: false,
      allAlign: 'center',
      projList: []
    }
  },
  computed: {
    totalAmount: {
      set (newValue) {
      },
      get () {
        if (XEUtils.isNumber(this.totalTrayNum) && XEUtils.isNumber(this.trayUnitPrice)) {
          return parseFloat(this.totalTrayNum * this.trayUnitPrice).toFixed(2)
        } else {
          return ''
        }
      }
    }
  },
  methods: {
    sumNum (field) {
      let count = 0
      this.settlementCountList.forEach(item => {
        count += (XEUtils.isNumber(item[field]) ? Number(item[field]) : 0)
      })
      return count
    },
    footerMethod ({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if ([
            'confirmWeight',
            'amount'
          ].includes(column.property)) {
            return this.sumNum(column.property)
          }
          return null
        })
      ]
    },
    calByPrice (scope) {
      const row = scope.row
      const price = row.unitPrice
      if (XEUtils.isNumber(row.confirmWeight)) {
        row.amount = parseFloat(parseFloat(price) * row.confirmWeight).toFixed(2)
      }
      this.list.forEach(item => {
        if (item.mortarType == row.strengthGrade && item.FormulaNo == row.concreteVarietyName && item.technicalRequirement == row.technical) {
          item.unitPrice = price
          if (XEUtils.isNumber(item.confirmWeight)) {
            item.amount = parseFloat(parseFloat(price) * item.confirmWeight).toFixed(2)
          }
        }
      })
    },
    reset () {
      this.searchModel = {
        OutFacEndDate: '',
        OutFacStartDate: '',
        projId: '',
        checkTime: moment().format('YYYY-MM-DD'),
        remark: ''
      }
      this.trayUnitPrice = 0.0 // 托盘单价
      this.primage = 0.0 // 运费补贴
      this.totalTrayNum = 0 // 签收托盘数
      this.totalAmount = 0.0 // 签收总金额
      this.list = []
      this.settlementCountList = []
    },
    reback () {
      this.$emit('toPage')
    },
    gainProjList () {
      // 关联工程
      getProjList0().then(res => {
        this.projList = res.data
      })
    },
    submit () {
      const settlementList = []
      this.list.forEach(item => settlementList.push(item.serialID))
      addModSettlement({
        settlementCheckId: this.param ? this.param.id : '',
        settlementList: settlementList,
        projId: this.searchModel.projId,
        projName: this.projList.filter(item => item.projId == this.searchModel.projId).projName,
        endShipDate: this.searchModel.OutFacEndDate,
        startShipDate: this.searchModel.OutFacStartDate,
        checkTime: this.searchModel.checkTime,
        settlementCountList: this.settlementCountList,
        totalAmount: this.totalAmount, // 签收总金额
        totalTrayNum: this.totalTrayNum, // 签收托盘数
        trayUnitPrice: this.trayUnitPrice, // 托盘单价
        primage: this.primage // 运费补贴
      }).then(res => {
        if (res.code === 200) {
          if (this.param) {
            this.$message.success('修改成功！')
          } else {
            this.$message.success('添加成功！')
          }
          this.$emit('toPage')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    refreshData () {
      if (this.param && this.refreshInit) {
        gainSettlementOne(this.param).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.searchModel = {
              projId: res.data.projId,
              OutFacEndDate: res.data.endShipDate,
              OutFacStartDate: res.data.startShipDate,
              checkTime: res.data.checkTime,
              remark: ''
            }
            this.list = res.data.list
            let totalTrayNum = 0 // 签收托盘
            this.list.forEach(item => {
              totalTrayNum += (parseFloat(item.sendTray) - parseFloat(item.receiveTray))
            })
            this.totalAmount = res.data.totalAmount// 签收总金额
            this.totalTrayNum = parseFloat(totalTrayNum).toFixed(0) || 0 // 签收托盘数
            this.trayUnitPrice = res.data.trayUnitPrice || 0 // 托盘单价
            this.primage = res.data.primage || 0 // 运费补贴
            this.settlementCountList = res.data.settlementCountList
            this.refreshInit = false
          }
        }).catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
      } else {
        gianSettlementList({
          ...this.searchModel
        }).then(res => {
          this.loading = false
          if (res.code == 200) {
            this.list = res.data.list
            this.settlementCountList = res.data.bustaskinfoVoList
            let totalTrayNum = 0 // 签收托盘
            this.list.forEach(item => {
              totalTrayNum += (parseFloat(item.sendTray) - parseFloat(item.receiveTray))
            })
            this.totalAmount = 0.0 // 签收总金额
            this.totalTrayNum = parseFloat(totalTrayNum).toFixed(0) // 签收托盘数
            this.trayUnitPrice = res.data.trayUnitPrice// 托盘单价
            this.primage = res.data.primage// 运费补贴
          }
        }).catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
      }
    },
    addRow () {
      this.$emit('toDetailPage', true, undefined)
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {
    }
  },
  mounted () {
    this.gainProjList()
    if (this.editMode && this.param) {
      this.refreshData()
    }
  }
}
</script>s

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
  .tableTitle{
    margin-top:10px;
    width:100%;
    color: #606266;
    padding-left:15px;
    font-size:15px;
    height:20px;
    font-weight: bold;
  }
  .tableTitle_border{
    margin-top: 30px;
    border-bottom: 1px solid #e8eaec;
  }
  .buttonWrapper{
    text-align: center;
    margin-top:20px;
  }
}
</style>
