<template>
  <el-dialog  class="abow_dialog" v-bind="{title:'打印',width:'90%'}"
              :visible.sync="visibleDialog"
              @closed="handlerClose"
              @open="handlerOpen">
    <div ref="printContent" style="display: flex;flex-direction: column" id="settleMortarTable">
      <table border="0" cellpadding="0" cellspacing="0" width="96%" style="margin:0 20px 0 20px;">
        <tbody>
          <tr style="text-align: center;">
            <td align="center" colspan="5">
              <span style="font-size: 18px; font-weight: bold; letter-spacing:2px">{{compName}}</span>
            </td>
          </tr>
          <tr style="text-align: center; height: 30px">
            <td align="center" colspan="5">
              <span id="subTitle" style="font-size: 16px; font-weight: bold; letter-spacing:1px">对账单{{startShipDate}}~{{endShipDate}}</span>
            </td>
          </tr>
          <tr>
            <td align="left">
              <span>工程名称：</span>
              <span>{{this.projName}}</span>
            </td>
            <td align="left">
            </td>
          </tr>
          <tr>
            <td align="left">
              <span>明细如下：</span>
            </td>
            <td align="right">
              <span>{{this.checkTime}}</span>
            </td>

          </tr>
        </tbody>
      </table>
      <table align="left" border="1" cellpadding="5" cellspacing="0" width="96%" style="margin:2px 20px 2px 20px;">
        <thead>
          <tr>
            <td width="3%" align="center">
              <span>编号</span>
            </td>
            <td width="13%" align="center">
              <span>发货日期</span>
            </td>
            <td width="10%" align="center">
              <span>发货号</span>
            </td>
            <td width="9%" align="center">
              <span>产品名称</span>
            </td>
            <td width="6%" align="center">
              <span>数量(吨)</span>
            </td>
            <td width="6%" align="center">
              <span>单价(元)</span>
            </td>
            <td width="10%" align="center">
              <span>金额(元)</span>
            </td>
            <td width="10%" align="center">
              <span>车号</span>
            </td>
            <td width="10%" align="center">
              <span>发出托板</span>
            </td>
            <td width="10%" align="center">
              <span>回收托板</span>
            </td>
            <td width="13%" align="center">
              <span>备注</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <td align="center"><span>{{index+1}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.createTime}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.serialID}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.FormulaNo}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.confirmWeight}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.unitPrice}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.amount}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.licPlate}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.sendTray}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.receiveTray}}</span></td>
            <td align="center"><span style="font-size:10px">{{item.remark}}</span></td>
          </tr>
          <tr>
            <td colspan="4" align="center"><span>砂浆合计</span></td>
            <td align="center"><span style="font-size:10px">{{totalSumSiginMortarMount0}}吨</span></td>
            <td align="center"></td>
            <td align="center"><span style="font-size:10px">{{totalAmount0}}元</span></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"></td>
            <td align="center"><span></span></td>
          </tr>
          <tr>
            <td colspan="4" align="center"><span>托板合计</span></td>
            <td align="center"><span style="font-size:10px">{{totalTrayNum}}个</span></td>
            <td align="center"><span style="font-size:10px">{{trayUnitPrice}}</span></td>
            <td align="center"><span style="font-size:10px">{{totalAmount0}}元</span></td>
            <td align="center"></td><td align="center"><span style="font-size:10px">{{totalSendTrayNum}}</span></td>
            <td align="center"><span style="font-size:10px">{{totalSendTrayNum}}</span></td>
            <td align="center"></td>
          </tr>
          <tr>
            <td colspan="4" align="center"><span>运费补贴</span></td>
            <td colspan="2" align="center"></td>
            <td align="center"><span style="font-size:10px" id="primage">{{this.primage}}元</span></td>
            <td colspan="4" align="center"></td>
          </tr>
          <tr>
            <td colspan="4" align="center"><span style="font-size: 14px; font-weight: bold;">合&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计</span></td>
            <td colspan="2" align="center"></td>
            <td align="center"><span style="font-size:10px" id="sumAmount">{{total}}元</span></td>
            <td colspan="4" align="center"></td>
          </tr>
        </tbody>
      </table>
      <table id="shipData" align="left" border="1" cellpadding="5" cellspacing="0" width="96%" style="margin:-4px 20px 2px 20px;">
        <tbody>
          <tr>
            <td width="100%" align="left">
              <span style="font-size: 14px; font-weight: bold;" id="bigMoney">大写:(人民币)</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table align="left" border="1" cellpadding="5" cellspacing="0" width="96%" style="margin:-5px 20px 2px 20px;">
        <tbody>
          <tr>
            <td width="15%" align="center" :rowspan="calRowspan">
              <span>结算内容</span>
            </td>
            <td width="15%" align="center">
              <span>产品名称</span>
            </td>
            <td width="15%" align="center">
              <span>数量(吨)</span>
            </td>
            <td width="10%" align="center">
              <span>单价(元)</span>
            </td>
            <td width="15%" align="center">
              <span>金额(元)</span>
            </td>
            <td width="15%" align="center">
              <span>备注</span>
            </td>
          </tr>
          <tr v-for="(item, index) in settlementCountList" :key="index">
            <td width="15%" align="center">
              <span>{{item.concreteVarietyName}}</span>
            </td>
            <td width="15%" align="center">
              <span>{{item.confirmWeight}}</span>
            </td>
            <td width="10%" align="center">
              <span>{{item.unitPrice}}</span>
            </td>
            <td width="15%" align="center">
              <span>{{item.amount}}</span>
            </td>
            <td width="15%" align="center">
              <span>{{item.remark}}</span>
            </td>
          </tr>
          <tr>
            <td width="15%" align="center">
              <span style="font-size: 14px; font-weight: bold;">砂浆合计</span>
            </td>
            <td width="15%" align="center"><span>{{totalSumSiginMortarMount0}}</span></td>
            <td width="10%" align="center"> <span></span></td>
            <td width="15%" align="center"><span>{{totalAmount0}}</span></td>
            <td width="15%" align="center"><span></span></td>
          </tr>
          <tr>
            <td width="15%" align="center">
              <span style="font-size: 14px; font-weight: bold;">托板合计</span>
            </td>
            <td width="15%" align="center">
              <span>{{totalTrayNum}}</span>
            </td>
            <td width="10%" align="center">
              <span>{{trayUnitPrice}}</span>
            </td>
            <td width="15%" align="center">
              <span>{{totalAmount}}</span>
            </td>
            <td width="15%" align="center">
              <span></span>
            </td>
          </tr>
          <tr>
            <td width="15%" align="center"><span style="font-size: 14px; font-weight: bold;">运费补贴</span></td>
            <td width="15%" align="center"><span></span></td><td width="10%" align="center"><span></span></td>
            <td width="15%" align="center"><span></span>{{primage}}</td>
            <td width="15%" align="center"><span></span></td>
          </tr>
          <tr>
            <td width="15%" align="center"><span style="font-size: 14px; font-weight: bold;">合&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计</span></td>
            <td width="15%" align="center"><span> </span></td><td width="10%" align="center"><span> </span></td>
            <td width="15%" align="center"><span style="font-size: 14px; font-weight: bold;">{{total}}元</span></td>
            <td width="15%" align="center"><span></span></td>
          </tr>
        </tbody>
      </table>

      <table align="left" border="1" cellpadding="5" cellspacing="0" width="96%" style="margin:-4px 20px 2px 20px;">
        <tbody>
          <tr>
            <td width="15%" rowspan="3" align="center">
              <span>情况说明</span>
            </td>
            <td width="85%" align="left">
              <span>附签字小票供核对以上数量，请核对签认，以便按照合同履行。</span>
            </td>
          </tr>
          <tr height="34px">
            <td width="85%" align="left">
              <span id="accountCheckRemark"><!-- 结算单价按上月上海市信息价下浮15%结算 --></span>
            </td>
          </tr>
          <tr>
            <td width="85%" align="left">
              <span id="noticeDateStr">注：本次对账至{{noticeDate}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table align="left" border="0" cellpadding="0" cellspacing="0" width="96%" style="margin:-4px 20px 2px 20px;">
        <tbody>
          <tr style="height:50px">
            <td width="25%" align="left">
              <span>需方单位盖章:</span>
            </td>
            <td width="25%" align="left">
              <span>{{firm}}</span>
            </td>
            <td width="25%" align="left">
              <span>供方单位盖章:</span>
            </td>
            <td width="25%" align="left">
              <span>{{compName}}</span>
            </td>
          </tr>
          <tr style="height:50px">
            <td width="25%" align="left">
              <span>经办人:</span>
            </td>
            <td width="25%" align="left">
              <span></span>
            </td>
            <td width="25%" align="left">
              <span>经办人:</span>
            </td>
            <td width="25%" align="left">
              <span></span>
            </td>
          </tr>
          <tr style="height:50px">
            <td width="25%" align="left">
              <span>日期:</span>
            </td>
            <td width="25%" align="left">
              <span></span>
            </td>
            <td width="25%" align="left">
              <span>日期:</span>
            </td>
            <td width="25%" align="left">
              <span></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </el-dialog>
</template>

<script>
import { gainSettlementOne } from '@/apis/saleManage/settlementMortar'
import moment from 'moment'
export default {
  name: 'settlePrint',
  props: {
    param: {
      type: Object // 结算单流水号
    },
    visible: {
      type: Boolean,
      default: false,
      require: true
    }
  },
  data () {
    return {
      calRowspan: 5,
      projectItems: [],
      regionArr: [],
      list: [],
      settlementCountList: [],
      trayUnitPrice: 0.0, // 托盘单价
      primage: 0.0, // 运费补贴
      totalTrayNum: 0, // 签收托盘数
      endShipDate: '',
      startShipDate: '',
      projName: '',
      compName: '',
      checkTime: '',
      noticeDate: '',
      totalSendTrayNum: 0, // 总发出托板数
      totalSumSiginMortarMount0: 0, // 总数量
      totalAmount0: 0, // 所有单价*数量
      totalAmount: 0,
      totalSignBackTrayNum: 0, // 总收回托板数
      total: 0,
      firm: ''
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('closePrint')
      },
      get () {
        return this.visible
      }
    }
  },
  methods: {
    printEvent5 () {
      const divEl = document.getElementById('settleMortarTable')
      this.$XPrint({
        sheetName: '结算单',
        content: divEl.innerHTML
      })
    },
    refreshData () {
      gainSettlementOne(this.param).then(res => {
        // this.loading = false
        if (res.code == 200) {
          this.settleData = res.data
          this.list = res.data.list
          let totalTrayNum = 0 // 签收托盘
          this.projName = res.data.projName
          this.compName = res.data.compName
          this.endShipDate = moment(res.data.endShipDate).format('YYYY-MM-DD')
          this.startShipDate = moment(res.data.startShipDate).format('YYYY-MM-DD')
          this.checkTime = moment(res.data.checkTime).format('YYYY-MM-DD')
          this.settlementCountList = res.data.settlementCountList
          // 计算发货单单价 、金额
          const list = this.list
          // 复制单价、金额
          this.settlementCountList.forEach(item => {
            list.forEach(eve => {
              if (eve.mortarType == item.strengthGrade && eve.FormulaNo == item.concreteVarietyName && item.technicalRequirement == item.technical) {
                eve.unitPrice = item.unitprice
                if (XEUtils.isNumber(eve.confirmWeight) && XEUtils.isNumber(eve.unitPrice)) {
                  eve.amount = parseFloat(parseFloat(eve.unitPrice) * eve.confirmWeight).toFixed(2)
                }
              }
            })
          })
          this.list = list
          let totalAmount0 = 0 // 除运费补贴，所有金额
          let totalSumSiginMortarMount0 = 0 // 总数量
          let totalSendTrayNum = 0
          let totalSignBackTrayNum = 0
          this.list.forEach(item => {
            totalTrayNum += (parseFloat(item.sendTray) - parseFloat(item.receiveTray))
            totalSendTrayNum += parseFloat(item.sendTray)
            totalSignBackTrayNum += parseFloat(item.receiveTray)
            totalSumSiginMortarMount0 += parseFloat(item.confirmWeight)
            totalAmount0 += XEUtils.isNumber(item.confirmWeight) && XEUtils.isNumber(item.unitPrice) ? parseFloat(parseFloat(item.unitPrice) * item.confirmWeight).toFixed(2) : 0
          })
          this.totalSendTrayNum = totalSendTrayNum
          this.totalAmount0 = totalAmount0
          this.totalSignBackTrayNum = totalSignBackTrayNum
          this.totalSumSiginMortarMount0 = totalSumSiginMortarMount0
          this.primage = res.data.primage || 0 // 运费补贴
          this.totalTrayNum = parseFloat(totalTrayNum).toFixed(0) || 0 // 签收托盘数
          this.trayUnitPrice = res.data.trayUnitPrice || 0 // 托盘单价
          this.totalAmount = XEUtils.isNumber(this.totalTrayNum) && XEUtils.isNumber(this.trayUnitPrice) ? parseFloat(parseFloat(this.trayUnitPrice) * this.totalTrayNum).toFixed(2) : 0// 签收总金额
          this.calRowspan = this.settlementCountList.length + 5
          const temp = this.checkTime.split('-')
          this.noticeDate = temp[0] + '年' + temp[1] + '月' + temp[2] + '日'
          this.firm = res.data.firm
          this.total = (XEUtils.isNumber(this.totalAmount0) ? this.totalAmount0 : 0) + (XEUtils.isNumber(this.totalAmount) ? this.totalAmount : 0) + (XEUtils.isNumber(this.primage) ? this.primage : 0)
        }
      })
    },
    handlerOpen () {
      this.refreshData()
      this.printEvent5()
    },

    handlerClose () {
      this.$emit('closePrint')
    }
  }
}
</script>

<style lang="scss" scoped>
   .abow_dialog {
     .el-dialog {
     margin: 0 auto !important;
       height:auto;
       overflow: hidden;
       overflow-y: scroll;
      /* .el-dialog__body {
       position: relative;
         left: 0;
         bottom: 0;
         right: 0;
         padding: 0;
         z-index: 1;
         overflow: hidden;
         overflow-y: scroll;
       }*/
     }
   }
   table tr{
     height:35px;
   }
   table{
     color:black;
   }
</style>
